body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button, .MuiButton-text, .MuiButton-root {
    text-transform: unset !important;
}

code {
    font-family: "Fira Mono";
    background-color: black;
    padding: 4px;
    border: 3px solid black;
    color: white;
}

html,
body,
#__next {
    height: 100%;
}

.MuiContainer-root {
    height: calc(100% - 64px);
}
